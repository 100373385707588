import cx from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import delay from 'lodash/delay'
import pick from 'lodash/pick'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import PopupButton from 'src/components/ui/atoms/PopupButton/PopupButton'
import InputModal from 'src/components/ui/molecules/InputModal'
import PageLoadingHandler from 'src/components/ui/molecules/PageLoadingHandler'
import DashboardOptions from 'src/components/ui/organisms/SavedDashboards/DashboardOptions'
import useAccount from 'src/hooks/useAccount'
import useDispatcher from 'src/hooks/useDispatcher'
import useFetch from 'src/hooks/useFetch'
import useFilterBar from 'src/hooks/useFilterBar'
import useHasChangeOnPage from 'src/hooks/useHasChangeOnPage'
import useIsMarketIntel from 'src/hooks/useIsMarketIntel'
import useProductPage from 'src/hooks/useProductPage'
import useSettings from 'src/hooks/useSettings'
import useTracking from 'src/hooks/useTracking'
import {
  resetComponentsSettings,
  resetSettingsLoadedAction,
  setComponentsSettingsLoading,
} from 'src/redux/actions/componentsSettingsActions'
import { useAppSelector } from 'src/redux/hooks'
import ComponentColor from 'src/types/ItemColor'
import SaveDashboardItemInterface from 'src/types/SaveDashboardItemInterface'
import { getApiUrl } from 'src/utils'
import { SubProduct, subProductsWithoutTargetPeriodInformations } from 'src/utils/product'
import { getDashboardKeyFn } from 'src/utils/saveDashboardUtils'
import { ComponentCode, ComponentsSettings } from 'src/utils/settings'
import SaveDashboardButton from './SaveDashboardButton'
import SaveDashboardsItem from './SaveDashboardsItem'

import * as styles from './DashboardsList.scss'

interface Props {
  printMode: boolean
  componentsCodes?: {
    exportDashboard: string
  }
  printModeAllowed?: boolean
}

const DASHBOARD_LABEL_MIN_LENGTH = 3
const DASHBOARD_LABEL_MAX_LENGTH = 33
const LabelErrorMessage = {
  max: 'Dashboard name must not exceed 30 characters',
  min: 'Dashboard name must exceed 2 characters',
}

const SavedDashboardsContainer = ({ printMode, componentsCodes, printModeAllowed }: Props) => {
  const {
    dashboardsList,
    defaultDashboard,
    setDashboardsList,
    setDefaultDashboard,
    favoritesDashboards,
    selectedsDashboards,
    updateSelectedsDashboards,
    updateFavoritesDashboards,
    clearQueryParams,
    isDashboardListLoading,
  } = useSettings()
  const { dashboardKey, code, program, product } = useProductPage()
  const { trackEvent } = useTracking()
  const isMarket = useIsMarketIntel()
  const componentColor = isMarket ? ComponentColor.orange : ComponentColor.blue

  const { settings, oldSettings } = useAppSelector(s => ({
    settings: s.componentsSettings.settings,
    oldSettings: s.componentsSettings.oldSettings,
  }))

  const settingsRef = useRef(settings)
  const oldSettingsRef = useRef(oldSettings)

  useEffect(() => {
    settingsRef.current = settings
    oldSettingsRef.current = oldSettings
  }, [settings, oldSettings])

  const filterBarSettings = settings[dashboardKey]?.[ComponentCode.FilterBar]
  const { setHasChangesOnDashboard } = useHasChangeOnPage()
  const { resetDataWarnings, resetFiltersShapes } = useFilterBar()

  const [displayModal, setDisplayModal] = useState<'' | 'edit' | 'copy' | 'save'>('')
  const [labelError, setLabelError] = useState(false)
  const [modalInputValue, setModalInputValue] = useState('')
  const [currentDashboard, setCurrentDashboard] = useState<SaveDashboardItemInterface | null>(null)
  const [hoveredRecord, setHoveredRecord] = useState<Record<number, boolean>>({})
  const [subProductDashboards, setSubProductDashboards] = useState<
    ReadonlyArray<SaveDashboardItemInterface>
  >([])
  const {
    informations: {
      current_profile: { id: currentProfileId },
    },
  } = useAccount()

  const resetSettingsLoadedDispatcher = useDispatcher(resetSettingsLoadedAction)
  const setComponentsSettingsLoadingDispatcher = useDispatcher(setComponentsSettingsLoading)
  const resetComponentsSettingsDispatcher = useDispatcher(resetComponentsSettings)

  useEffect(() => {
    setSubProductDashboards(
      dashboardsList.filter(
        item =>
          getDashboardKeyFn({
            subProductCode: item.sub_product!,
            programId: item.program_id,
          }) === dashboardKey,
      ),
    )
  }, [dashboardsList, dashboardKey])

  const [, executeGetDashboard] = useFetch<ReadonlyArray<SaveDashboardItemInterface>>(
    { method: 'GET' },
    { manual: true },
  )
  const [, executeSaveNewDashboard] = useFetch<SaveDashboardItemInterface[]>(
    {
      url: `${getApiUrl()}/api/admin/saved_dashboards`,
      method: 'POST',
    },
    { manual: true },
  )
  const [, executeSaveDashboard] = useFetch({ method: 'PUT' }, { manual: true })
  const [, executeRenameDashboard] = useFetch<SaveDashboardItemInterface[]>(
    { method: 'PUT' },
    { manual: true },
  )
  const [, executeDuplicateDashboard] = useFetch<SaveDashboardItemInterface[]>(
    { method: 'PUT' },
    { manual: true },
  )
  const [, executeDeleteDashboard] = useFetch<SaveDashboardItemInterface[]>(
    { method: 'DELETE' },
    { manual: true },
  )
  const [, executeSetFavoriteDashboard] = useFetch<SaveDashboardItemInterface[]>(
    { method: 'PUT' },
    { manual: true },
  )

  const handleModalChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setModalInputValue(event.target.value),
    [],
  )

  const labelCheck = useCallback(
    (label: string): boolean => {
      const formattedLabel = label.toUpperCase().trim()
      const sameDashboardLabel = dashboardsList.some(
        dashboard =>
          getDashboardKeyFn({
            subProductCode: dashboard.sub_product!,
            programId: dashboard.program_id,
          }) === dashboardKey && dashboard.label.toUpperCase().trim() === formattedLabel,
      )
      if (sameDashboardLabel) {
        setLabelError(true)
        toast.error('A query already has this name')
        return true
      }

      if (formattedLabel.length < DASHBOARD_LABEL_MIN_LENGTH) {
        setLabelError(true)
        toast.error(LabelErrorMessage.min)
        return true
      }

      if (formattedLabel.length > DASHBOARD_LABEL_MAX_LENGTH) {
        setLabelError(true)
        toast.error(LabelErrorMessage.max)
        return true
      }

      setDisplayModal('')
      setModalInputValue('')
      setLabelError(false)
      return false
    },
    [dashboardsList, dashboardKey],
  )

  const handleHoverItem = useCallback((isHover: boolean, index: number) => {
    if (isHover) {
      setHoveredRecord(prevState => ({ ...prevState, [index]: true }))
    } else {
      setHoveredRecord(prevState => ({ ...prevState, [index]: false }))
    }
  }, [])

  /**
   * Reset states
   */
  const resetState = useCallback(() => {
    setCurrentDashboard(null)
    setDisplayModal('')
    setModalInputValue('')
    setLabelError(false)
  }, [])

  /**
   * Get/Set dashboard from API or default dashboard
   * @param Dashboard dashboard
   */
  const getDashboard = useCallback(
    async (dashboard: SaveDashboardItemInterface) => {
      setComponentsSettingsLoadingDispatcher(true)
      resetSettingsLoadedDispatcher()
      clearQueryParams({ from: '' })
      resetFiltersShapes()
      resetDataWarnings()

      const defaultDashboardCloned = cloneDeep(defaultDashboard)
      if (defaultDashboardCloned.label !== dashboard.label) {
        const { data }: any = await executeGetDashboard({
          url: `${getApiUrl()}/api/admin/saved_dashboards/${dashboard.id}`,
          params: {
            program_id: program,
          },
        })
        dashboard.selected = true
        defaultDashboardCloned.selected = false
        const { components_settings } = data
        const settings = {
          ...settingsRef.current,
          [dashboardKey]: components_settings[code],
        }
        const oldSettings = {
          ...oldSettingsRef.current,
          [dashboardKey]: components_settings[code],
        }
        updateSelectedsDashboards({ [dashboardKey]: dashboard })
        setDefaultDashboard(defaultDashboardCloned)
        resetComponentsSettingsDispatcher(settings, oldSettings)
      } else {
        const settings = { ...settingsRef.current, [dashboardKey]: {} }
        const oldSettings = { ...oldSettingsRef.current, [dashboardKey]: {} }
        defaultDashboardCloned.selected = true
        setDefaultDashboard(defaultDashboardCloned)
        updateSelectedsDashboards({ [dashboardKey]: defaultDashboardCloned })
        resetComponentsSettingsDispatcher(settings, oldSettings)
      }
      resetState()
      setHasChangesOnDashboard(false)
      delay(() => setComponentsSettingsLoadingDispatcher(false), 2000)
    },
    [
      clearQueryParams,
      defaultDashboard,
      code,
      executeGetDashboard,
      resetComponentsSettingsDispatcher,
      resetDataWarnings,
      resetFiltersShapes,
      resetSettingsLoadedDispatcher,
      resetState,
      setComponentsSettingsLoadingDispatcher,
      setDefaultDashboard,
      setHasChangesOnDashboard,
      updateSelectedsDashboards,
      dashboardKey,
      program,
    ],
  )

  /**
   * Payload for saveNewDashboard/saveDashboard
   * @param label string
   */
  const saveDashboardPayloadData = useCallback(
    (label: string) => {
      const comparisonDisabled = filterBarSettings?.timePeriod?.comparisonDisabled
      let target_period_informations: object = {
        period: filterBarSettings?.timePeriod?.selectedRange,
        frequency: filterBarSettings?.timePeriod.frequency || filterBarSettings?.timePeriod.view,
        custom_period: {
          start_date: filterBarSettings?.timePeriod.startDate,
          end_date: filterBarSettings?.timePeriod.endDate,
        },
        comparisonDisabled,
        comparison: filterBarSettings?.timePeriod?.comparison,
        enableCompare: filterBarSettings?.timePeriod?.enableCompare,
      }

      const settingsToSave = pick(settingsRef.current, [dashboardKey])

      const components_settings = {
        [code]: settingsToSave[dashboardKey],
      }

      const payload: {
        product: string
        sub_product: string
        components_settings: Pick<ComponentsSettings, string>
        label: string
        program_id?: string | number
      } = {
        product,
        sub_product: code,
        components_settings,
        label,
        program_id: program,
      }

      if (program) {
        payload.program_id = program
      }

      if (!subProductsWithoutTargetPeriodInformations.includes(code as SubProduct)) {
        if (!comparisonDisabled) {
          target_period_informations = {
            ...target_period_informations,
            compare_to: filterBarSettings?.timePeriod?.selectedRangeCompare,
            custom_period: {
              ...target_period_informations['custom_period'],
              previous_start_date: filterBarSettings?.timePeriod.startDateCompare,
              previous_end_date: filterBarSettings?.timePeriod.endDateCompare,
            },
          }
        }
        payload['target_period_informations'] = target_period_informations
      }

      return { saved_dashboard: payload }
    },
    [filterBarSettings, code, product, settingsRef, program],
  )

  const saveNewDashboard = useCallback(
    async (dashboard: SaveDashboardItemInterface) => {
      const { label } = dashboard
      const error = labelCheck(label)

      if (error) return
      toast
        .promise(
          executeSaveNewDashboard({
            data: saveDashboardPayloadData(label),
          }),
          {
            loading: 'Please wait...',
            success: (
              <span>
                <b>{label}</b> dashboard successfully created
              </span>
            ),
            error: 'An error occurred. Please retry.',
          },
          { duration: 4000 },
        )
        .then(response => {
          const dashboardsDataList = response.data
          const dashboards = dashboardsDataList.map(d => {
            if (
              d.sub_product === code &&
              (program ? d.program_id?.toString() === program.toString() : true)
            ) {
              if (d.favorite) {
                d.favorite && updateFavoritesDashboards({ [dashboardKey]: d })
              }
              if (d.label === label) {
                dashboard.id = d.id
              }
            }
            return d
          })
          const settings = settingsRef.current
          dashboard.selected = true
          defaultDashboard.selected = false
          setDefaultDashboard(defaultDashboard)
          updateSelectedsDashboards({ [dashboardKey]: dashboard })
          setDashboardsList(dashboards)
          setHasChangesOnDashboard(false)
          resetState()
          resetComponentsSettingsDispatcher(settings, settings)
        })
    },
    [
      defaultDashboard,
      executeSaveNewDashboard,
      labelCheck,
      dashboardKey,
      resetComponentsSettingsDispatcher,
      resetState,
      saveDashboardPayloadData,
      setDashboardsList,
      setDefaultDashboard,
      setHasChangesOnDashboard,
      updateFavoritesDashboards,
      updateSelectedsDashboards,
    ],
  )

  const saveDashboard = async (dashboard: SaveDashboardItemInterface) => {
    const { id, label } = dashboard
    toast
      .promise(
        executeSaveDashboard({
          url: `${getApiUrl()}/api/admin/saved_dashboards/${id}`,
          data: saveDashboardPayloadData(label),
        }),
        {
          loading: 'Please wait...',
          success: (
            <span>
              <b>{label}</b> dashboard successfully saved
            </span>
          ),
          error: 'An error occurred. Please retry.',
        },
        { duration: 4000 },
      )
      .then(() => {
        const settings = settingsRef.current
        resetComponentsSettingsDispatcher(settings, settings)
        setHasChangesOnDashboard(false)
        resetState()
      })
  }

  const renameDashboard = useCallback(
    async (dashboard: SaveDashboardItemInterface, label: string) => {
      const error = labelCheck(label)
      if (error) return
      toast
        .promise(
          executeRenameDashboard({
            url: `${getApiUrl()}/api/admin/saved_dashboards/${dashboard.id}/rename`,
            data: { saved_dashboard: { label, program_id: program } },
          }),
          {
            loading: 'Please wait...',
            success: (
              <span>
                <b>{label}</b> dashboard successfully renamed
              </span>
            ),
            error: 'An error occurred. Please retry.',
          },
          { duration: 4000 },
        )
        .then(() => {
          const dashboards = dashboardsList.map(d => {
            if (d.sub_product === code && dashboard.id === d.id) {
              d.label = label
              d.selected = selectedsDashboards[dashboardKey].id === d.id
            }
            return d
          })
          setDashboardsList(dashboards)
          resetState()
        })
    },
    [
      dashboardsList,
      executeRenameDashboard,
      labelCheck,
      dashboardKey,
      program,
      resetState,
      selectedsDashboards,
      setDashboardsList,
    ],
  )

  const duplicateDashboard = useCallback(
    async (dashboard: SaveDashboardItemInterface, label: string) => {
      const error = labelCheck(label)
      if (error) return
      toast
        .promise(
          executeDuplicateDashboard({
            url: `${getApiUrl()}/api/admin/saved_dashboards/${dashboard.id}/duplicate`,
            data: { saved_dashboard: { label } },
          }),
          {
            loading: 'Please wait...',
            success: (
              <span>
                <b>{label}</b> dashboard successfully duplicated
              </span>
            ),
            error: 'An error occurred. Please retry.',
          },
          { duration: 4000 },
        )
        .then(response => {
          const dashboardsListData = response.data
          setDashboardsList(dashboardsListData)
          resetState()
        })
    },
    [executeDuplicateDashboard, labelCheck, resetState, setDashboardsList],
  )

  const deleteDashboard = useCallback(
    async (dashboard: SaveDashboardItemInterface) => {
      toast
        .promise(
          executeDeleteDashboard({
            url: `${getApiUrl()}/api/admin/saved_dashboards/${dashboard.id}`,
            params: {
              program_id: program,
            },
          }),
          {
            loading: 'Please wait...',
            success: (
              <span>
                <b>{dashboard.label}</b> dashboard successfully deleted
              </span>
            ),
            error: 'An error occurred. Please retry.',
          },
          { duration: 4000 },
        )
        .then(() => {
          const dashboardsFiltered = dashboardsList.filter(d => dashboard.id !== d.id) // Remove deleted dashboard
          const defaultDashboardCloned = cloneDeep(defaultDashboard)
          const favoriteDashboardCloned = cloneDeep(favoritesDashboards[dashboardKey])
          const selectedDashboardCloned = cloneDeep(selectedsDashboards[dashboardKey])
          favoriteDashboardCloned.favorite = !(
            favoritesDashboards[dashboardKey].id === dashboard.id
          )
          selectedDashboardCloned.selected = !(
            selectedsDashboards[dashboardKey].id === dashboard.id
          )
          defaultDashboardCloned.selected = false
          if (dashboardsFiltered.length === 0 || !favoriteDashboardCloned?.favorite) {
            // If there is no favorite dashboard or dashboards list is empty, the default is used
            defaultDashboardCloned.favorite = true
            updateFavoritesDashboards({ [dashboardKey]: defaultDashboardCloned })
          }
          // If there is no selected dashboard, the default is used
          if (!selectedDashboardCloned?.selected) {
            defaultDashboardCloned.selected = true
            updateSelectedsDashboards({ [dashboardKey]: defaultDashboardCloned })
          }
          setDashboardsList(dashboardsFiltered)
          setDefaultDashboard(defaultDashboardCloned)
          defaultDashboardCloned.selected && getDashboard(defaultDashboardCloned)
          resetState()
        })
    },
    [
      dashboardsList,
      defaultDashboard,
      executeDeleteDashboard,
      favoritesDashboards,
      getDashboard,
      code,
      resetState,
      selectedsDashboards,
      setDashboardsList,
      setDefaultDashboard,
      updateFavoritesDashboards,
      updateSelectedsDashboards,
    ],
  )

  const setFavorite = useCallback(
    async (dashboard: SaveDashboardItemInterface) => {
      toast
        .promise(
          executeSetFavoriteDashboard({
            url: `${getApiUrl()}/api/admin/saved_dashboards/${dashboard.id}/favorite`,
            data: { saved_dashboard: { sub_product: code, program_id: program } },
          }),
          {
            loading: 'Please wait...',
            success: (
              <span>
                <b>{dashboard.label}</b> set as favorite
              </span>
            ),
            error: 'An error occurred. Please retry.',
          },
          { duration: 4000 },
        )
        .then(() => {
          const defaultDashboardCloned = cloneDeep(defaultDashboard)
          dashboard.favorite = true
          defaultDashboardCloned.favorite = dashboard.id === defaultDashboardCloned.id
          updateFavoritesDashboards({ [dashboardKey]: dashboard })
          setDefaultDashboard(defaultDashboardCloned)
          resetState()
        })
    },
    [
      defaultDashboard,
      dashboardKey,
      executeSetFavoriteDashboard,
      code,
      program,
      resetState,
      setDefaultDashboard,
      updateFavoritesDashboards,
    ],
  )

  const handleCloseInputModal = useCallback(() => {
    setCurrentDashboard(null)
    setDisplayModal('')
    setModalInputValue('')
    setLabelError(false)
  }, [])

  const handleSaveDashboard = useCallback(() => {
    if (displayModal === 'edit') {
      renameDashboard(currentDashboard as SaveDashboardItemInterface, modalInputValue)
      trackEvent({ action: 'Click: Rename Dashboard' })
    } else {
      duplicateDashboard(currentDashboard as SaveDashboardItemInterface, modalInputValue)
      trackEvent({ action: 'Click: Duplicate Dashboard' })
    }
  }, [
    currentDashboard,
    displayModal,
    duplicateDashboard,
    modalInputValue,
    renameDashboard,
    trackEvent,
  ])

  const selected = selectedsDashboards[dashboardKey] // Selected dashboard (For current sub product)
  const favorite = favoritesDashboards[dashboardKey] // Favorited dashboard (For current sub product)
  const show = displayModal === 'copy' || displayModal === 'edit'

  const listSavedDashboards = [
    defaultDashboard,
    ...[...subProductDashboards].sort((a, b) => {
      if (a.default_dashboard) return -1
      if (b.default_dashboard) return 1
      return 0
    }),
  ]

  const handleEditDashboard = useCallback((dashboard: SaveDashboardItemInterface) => {
    setDisplayModal('edit')
    setCurrentDashboard(dashboard)
    setModalInputValue(dashboard.label)
  }, [])

  const unViewedSavedDashboardCount = listSavedDashboards.filter(d => d.viewed === false).length

  useEffect(() => {
    // Mark as viewed if the dashboard is selected
    if (selected?.viewed === false) {
      setDashboardsList(d =>
        d.map(d => {
          if (d.id === selected.id) {
            d.viewed = true
          }
          return d
        }),
      )
    }
  }, [selected, setDashboardsList])

  const currentProfileIdRef = useRef<string | null>(currentProfileId)

  useEffect(() => {
    if (currentProfileIdRef.current !== currentProfileId) {
      currentProfileIdRef.current = currentProfileId
      return
    }
    if (!isDashboardListLoading && favorite) {
      currentProfileIdRef.current = currentProfileId
      trackEvent({
        action: 'Run report: Dashboard Name',
        label: favorite.label,
      })
    }
  }, [favorite, currentProfileId, isDashboardListLoading])

  return (
    <>
      <PageLoadingHandler selectedSavedDashboard={selected} exportId={null} />
      <PopupButton
        title="Save Dashboard"
        buttonComponent={
          <SaveDashboardButton
            unViewedDashboardCount={unViewedSavedDashboardCount}
            label={selected?.label || ''}
            componentColor={componentColor}
            labelPendoSelector={
              selected?.default_dashboard ? 'dashboard-curation-monitoring-header' : undefined
            }
          />
        }
        popupFixedProps={{
          anchorOrigin: 'left',
          style: {
            transform: 'translate(28px, 12px)',
          },
        }}
        classes={{
          Popup: styles.SavedDashboardList,
        }}
        popupComponent={
          <div className={styles.SavedDashboardListContent}>
            {listSavedDashboards.map((item, index) => (
              <SaveDashboardsItem
                key={item.id}
                deleteDashboard={deleteDashboard}
                isDefaultDashboard={item.id === defaultDashboard.id}
                isFavorite={favorite?.id === item.id}
                isHovered={hoveredRecord[index]}
                item={item}
                loadDashboard={getDashboard}
                onHover={isHover => handleHoverItem(isHover, index)}
                setAsCurrentDashboard={setCurrentDashboard}
                editDashboard={handleEditDashboard}
                setDisplayModal={setDisplayModal}
                setToFavoriteDashboard={setFavorite}
                isSelected={selected?.id === item.id}
                className={cx({ [styles.OptionsEven]: index % 2 === 1 })}
                componentColor={componentColor}
              />
            ))}
          </div>
        }
        needToPassChildToggleProps={false}
      />

      <InputModal
        open={show}
        onClose={handleCloseInputModal}
        onChange={handleModalChange}
        value={modalInputValue}
        hasError={labelError}
        submit={handleSaveDashboard}
        title={displayModal === 'copy' ? 'Create a copy' : 'Rename'}
        buttonLabel="Save"
        inputTestId={displayModal === 'copy' ? 'copy-dashboard-input' : 'rename-dashboard-input'}
      />

      {!printMode && (
        <DashboardOptions
          disabled={false}
          componentsCodes={componentsCodes}
          printModeAllowed={printModeAllowed}
          displayModal={displayModal}
          labelError={labelError}
          modalInputValue={modalInputValue}
          handleModalChange={handleModalChange}
          setModalInputValue={setModalInputValue}
          setLabelError={setLabelError}
          setDisplayModal={setDisplayModal}
          saveDashboard={() => saveDashboard(selected)}
          saveNewDashboard={(data: any) => saveNewDashboard(data)}
        />
      )}
    </>
  )
}

export default SavedDashboardsContainer
