import EcosystemSizeType from 'src/utils/constants/Ecosystem'
import { DimensionInterface, EcosystemDimensionInterface } from './DimensionInterface'

export interface EcosystemComponentPropsInterface {
  selectCategories?: {
    maxItemsPerColumns?: number
  }
  multipleEcosystems?: boolean
  staticMessage?: string
  displayPrimaryDropdown?: boolean
  displaySecondDropdown?: boolean
}

export enum EcosystemCellType {
  compare = 'compare',
  currency = 'currency',
  gapDaily = 'gap_daily',
  label = 'label',
  metric = 'metric',
  percentage = 'percentage',
  position = 'position',
  rawPercentage = 'rawPercentage',
  revVar = 'rev_var',
  revVarPercent = 'rev_var_percent',
  shareDaily = 'share_daily',
  gauge = 'gauge',
  link = 'link',
  date = 'date',
  text = 'text',
  badge = 'badge',
  dimension = 'dimension',
  arrow = 'arrow',
  checkboxLaunching = 'checkboxLaunching',
  mappingCheckbox = 'mappingCheckbox',
  optionCell = 'option_cell',
  indicators = 'indicators',
  sparklines = 'sparklines',
  curatedMetric = 'curated_metric',
}

enum FilterKind {
  Cursor = 'cursor',
  Dropdown = 'dropdown',
  Calendar = 'calendar',
}

enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface AddEcosystemInput {
  size: EcosystemSizeType
  selectedDimension: EcosystemDimensionInterface | undefined
  selectedSecondaryDimension: EcosystemDimensionInterface | undefined
  selectedTemplate: EcosystemTemplate | undefined
  isBenchmark: boolean
}

export interface AddSmallEcosystemInput
  extends Omit<AddEcosystemInput, 'selectedSecondaryDimension'> {
  isAddEcosystem: true
}

export type EcosystemOptionValue = boolean | string | ReadonlyArray<string>

export enum EcosystemOptionType {
  LIST = 'list',
  NESTED_OPTIONS = 'nested_options',
  SWITCH_BUTTON = 'switch_button',
  CHECKBOX = 'checkbox',
}

export interface EcosystemOption {
  id: string
  default?: EcosystemOptionValue
  type: EcosystemOptionType
  label?: string
  disable_on_comparison?: boolean
  values?: ReadonlyArray<{ label: string; value: EcosystemOptionValue }>
  activation_condition?: {
    [id: string]: EcosystemOptionValue
  } // Active when one of columns is visible on the ecosystem
  always_on_for?: string[] // Always on for specific dimension id
}

export interface EcosystemOptionsSection {
  label: string
  options: ReadonlyArray<EcosystemOption>
}

export interface EcosystemColumn {
  id: string
  label: string
  order?: number
  type?: string
  value_type?: string
  filter_kind?: FilterKind
  default_active?: boolean
  filter_config?: { [key: string]: any }
  filtered_property?: string
  is_default_sort?: boolean
  default_sort_direction?: SortDirection
  is_highlight?: boolean
  is_sortable?: boolean
  is_strong?: boolean
  raw_shape?: {
    decimals?: number // Decimal to show
    variation?: boolean
    variation_symbol?: string
  }
  variant?: string
  category?: string
  is_compare?: boolean
  color?: string
  tooltip?: string
  show_in_chart?: boolean // Check if metric will be shown in mode chart
  metric?: string
  total_by_day?: boolean // Check if total by day will be shown
  fetchable?: boolean
  enable_coverage_display?: boolean
  column_group?: string
}

export interface EcosystemTemplate {
  id: string
  label: string
  category: { label: string; id: string; background_color: string }
  columns: ReadonlyArray<EcosystemColumn>
  column_categories?: ReadonlyArray<string>
  description: string
  insight?: {
    message: string
  }
  insight_alert?: boolean
  order_by: string
  order: SortDirection
  disable?: boolean // hidden from list
  show_chart_mode?: boolean // Check if template has chartMode
  need_compare?: boolean // Check if template is enabled only in compare mode
  ec_type?: string
  gross_revenue?: boolean // is gross revenue
  need_service_gross_revenue?: boolean // need service gross revenue
  'add-on'?: string // gross revenue addon,
  clickable?: boolean // disabled but not hidden
}

export interface EcosystemRowConfig {
  id: string
  label: string
  type?: string
  field_type?: string
  is_sortable?: boolean
  category?: string
  fetchable?: boolean
  api?: string
  alignment?: string
  default_active?: boolean
  is_strong?: boolean
  order?: number
}

export interface EcosystemRow {
  data: ReadonlyArray<any>
  children?: ReadonlyArray<any>
}

export interface EcosystemSettings {
  id: number
  current_page?: number
  template?: {
    id: string
    label: string
  }
  primaryDimension?: DimensionInterface
  secondDimension?: DimensionInterface
  chartmode?: string
  chartmode_selected_items?: any[]
  size?: EcosystemSizeType
  isBenchmark?: boolean
  rowPerPage?: number
}

export interface SmallEcosystemSettings extends EcosystemSettings {
  isEcosystem: boolean
}

export enum OptionType {
  LIST = 'list',
  NESTED_OPTIONS = 'nested_options',
  SWITCH_BUTTON = 'switch_button',
  CHECKBOX = 'checkbox',
}

export interface EcosystemContext {
  [name: string]: boolean | string | ReadonlyArray<string>
}
