import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'
import { History } from 'history'

import { AccountInformations } from './account'
import { SENTRY_URL } from './config'
import { getUserAccountNameFn } from './userUtils'

export const initSentryFn = (history: History) => {
  if (!SENTRY_URL) {
    return
  }
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: 1.0,
    beforeSend: (event, hint) => {
      const error = hint?.originalException as Error
      if (error && axios.isCancel(error)) {
        return null
      }
      // Not send errors from chrome extension
      if ((error as Error)?.stack?.includes('chrome-extension')) {
        return null
      }
      if (
        (error as AxiosError)?.response?.status === 403 ||
        (error as AxiosError)?.response?.status === 401
      ) {
        return null
      }
      return event
    },
  })
}

export const setSentryUserFn = (user: AccountInformations | null) => {
  if (!SENTRY_URL || !user) {
    return
  }
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: getUserAccountNameFn(user),
  })
  if (user.default_company) {
    Sentry.setTag('client', user.default_company.name)
  }
}

export const setExtraSentryFn = (key: string, value: string | null) => {
  if (!SENTRY_URL) {
    return
  }
  Sentry.setExtra(key, value)
}

export const setSentryTagFn = (key: string, value: string | null) => {
  if (!SENTRY_URL) {
    return
  }
  Sentry.setTag(key, value)
}

export const sendSentryErrorFn = (error: Error) => {
  if (!SENTRY_URL) {
    return
  }
  Sentry.captureException(error)
}
