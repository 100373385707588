import cx from 'classnames'
import { FC } from 'react'

import ChevronDown from 'src/components/ui/atoms/Icons/ChevronDown'
import ChevronUp from 'src/components/ui/atoms/Icons/ChevronUp'
import NotificationBadgeNumber from 'src/components/ui/atoms/NotificationBadgeNumber'
import usePrinter from 'src/hooks/usePrinter'
import ComponentColor from 'src/types/ItemColor'

import * as styles from './DashboardsList.scss'

interface SaveDashboardButtonProps {
  label: string
  toggled?: boolean
  unViewedDashboardCount?: number
  componentColor?: ComponentColor
  labelPendoSelector?: string
}

const SaveDashboardButton: FC<SaveDashboardButtonProps> = ({
  label,
  toggled,
  unViewedDashboardCount,
  componentColor,
  labelPendoSelector,
}) => {
  const Icon = toggled ? ChevronUp : ChevronDown
  const { printMode } = usePrinter()

  const isOrange = componentColor === ComponentColor.orange

  return (
    <div
      className={cx({
        [styles.SaveDashboardButton]: true,
        [styles.SaveDashboardButtonOrange]: isOrange,
        [styles.SaveDashboardButtonPrintMode]: printMode,
      })}
    >
      <span
        className={cx(styles.SaveDashboardButtonLabel, {
          [styles.SaveDashboardButtonPrintModeLabel]: printMode,
        })}
        data-pendo={labelPendoSelector}
      >
        {label}
      </span>
      {!printMode && (
        <NotificationBadgeNumber
          className={styles.SaveDashboardButtonNotification}
          notificationCount={unViewedDashboardCount || 0}
        />
      )}
      {!printMode && (
        <Icon
          className={styles.SaveDashboardButtonDropdownIcon}
          preserveAspectRatio="none"
          width="10"
          height="7"
        />
      )}
    </div>
  )
}

export default SaveDashboardButton
