import cx from 'classnames'
import parseHtml from 'html-react-parser'
import { FC, HTMLAttributes, ReactNode } from 'react'

import ComponentColor from 'src/types/ItemColor'
import { getComponentColorClassNameFn } from 'src/utils/componentColorUtils'
import { shapeNumberFn, ShapeNumberOptions, toFixedNumberFn } from 'src/utils/shapeNumber/number'
import ArrowIcon from '../Icons/ArrowIcon'

import * as styles from './Number.scss'

type VariationSymbol = {
  positive: ReactNode
  negative: ReactNode
}

export const VariationSymbols: { [key: string]: VariationSymbol } = {
  arrow: {
    positive: <ArrowIcon className={styles.ArrowVariation} />,
    negative: <ArrowIcon className={cx(styles.ArrowVariation, styles.ArrowVariationDown)} />,
  },
  plus_minus: { positive: '+', negative: '-' },
  none: { positive: null, negative: null },
}

export interface NumberProps extends HTMLAttributes<HTMLSpanElement> {
  number: number
  shapeNumberOptions?: ShapeNumberOptions
  variationSymbol?: string
  variation?: boolean
  isPositive?: boolean
  equal?: boolean
  badge?: boolean
  neutral?: boolean
  parenthesis?: boolean
  bold?: boolean
  formatCurrency?: boolean
  withVariation?: boolean
  isApproximation?: boolean
  infinity?: boolean
  componentColor?: ComponentColor
  isBadgeHovered?: boolean
}

const defaultShapeNumberOptions = {
  decimals: 2,
  zeroFormat: false,
  displayUnit: true,
  truncate: true,
}

const Number: FC<NumberProps> = ({
  number,
  variation = false,
  variationSymbol = 'plus_minus',
  isPositive: isNumberPositive,
  shapeNumberOptions,
  equal: isNumberEqual,
  badge,
  neutral = false,
  parenthesis = false,
  bold = false,
  className,
  formatCurrency,
  withVariation = false,
  isApproximation,
  infinity,
  componentColor,
  isBadgeHovered,
  color,
  ...othersProps
}) => {
  const options = { ...defaultShapeNumberOptions, ...shapeNumberOptions }
  const fixedNumber = toFixedNumberFn(number, options?.decimals)
  const formattedNumber = shapeNumberFn(Math.abs(fixedNumber), options, formatCurrency)
  const parsedFormattedNumber = parseHtml(formattedNumber)

  if (!variation) {
    return (
      <span
        data-testid="number-without-variation"
        className={cx(
          styles.Number,
          getComponentColorClassNameFn({
            componentColor,
            fields: componentColor === ComponentColor.orange ? ['color'] : [],
          }),
          className,
        )}
        style={{ color }}
        {...othersProps}
      >
        {isApproximation && ' ≈ '}
        {parsedFormattedNumber}
      </span>
    )
  }

  const equal = isNumberEqual !== undefined ? isNumberEqual : fixedNumber === 0
  const isPositive = isNumberPositive !== undefined ? isNumberPositive : fixedNumber > 0
  const symbol = isPositive
    ? VariationSymbols[variationSymbol].positive
    : VariationSymbols[variationSymbol].negative

  return (
    <span
      data-testid="number-with-variation"
      style={{ color }}
      className={cx(
        {
          [styles.Number]: true,
          [styles.NumberWithVariation]: withVariation,
          [styles.Badge]: badge,
          [styles.Positive]: !equal && !neutral && isPositive,
          [styles.Negative]: !equal && !neutral && !isPositive,
          [styles.Neutral]: equal,
          [styles.PositiveHovered]: isBadgeHovered && !equal && !neutral && isPositive,
          [styles.NegativeHovered]: isBadgeHovered && !equal && !neutral && !isPositive,
          [styles.NeutralHovered]: isBadgeHovered && equal,
          [styles.Bold]: bold,
        },
        className,
      )}
      {...othersProps}
    >
      {parenthesis ? '(' : ''}
      {variationSymbol !== 'arrow' && isApproximation && ' ≈ '}
      {!equal ? (
        <span className={cx({ [styles.ArrowVariationWithMargin]: withVariation })}>{symbol}</span>
      ) : (
        ''
      )}
      {variationSymbol === 'arrow' && isApproximation && ' ≈ '}
      {!(parenthesis && equal) && ' '}
      {parsedFormattedNumber}
      {parenthesis ? ')' : ''}
    </span>
  )
}

export default Number
