import cx from 'classnames'

import MetricBoxArrowUp from 'src/components/ui/atoms/Icons/MetricBoxArrowUp'
import Number, { Props as NumberProps, VariationSymbols } from 'src/components/ui/atoms/Number'
import { ShapeNumberOptions } from 'src/utils/shapeNumber/number'

import * as styles from './Percentage.scss'

interface Props extends NumberProps {
  infinite?: boolean
  infiniteThresold?: number
  className?: string
  comparison?: boolean
}

const defaultShapeNumberOptions: ShapeNumberOptions = {
  decimals: 0,
}

const Percentage = ({
  number,
  variation,
  variationSymbol,
  shapeNumberOptions,
  isPositive,
  infinite = false,
  infiniteThresold = 9999,
  neutral,
  badge,
  className,
  comparison,
  isApproximation,
  color,
  ...othersProps
}: Props) => {
  const options = { ...defaultShapeNumberOptions, ...shapeNumberOptions }
  const symbol = variationSymbol ? (
    VariationSymbols[variationSymbol as string].positive
  ) : (
    <MetricBoxArrowUp className={styles.Icon} />
  )
  const infiniteClasses = cx(
    {
      [styles.InfiniteSymbol]: !neutral && !badge && variation,
      [styles.Neutral]: neutral,
      [styles.Badge]: !neutral && badge,
    },
    className,
  )

  if (comparison === false)
    return (
      <span className={cx(styles.Neutral, styles.NeutralComparisonDisabled, className)}> --% </span>
    )

  return infinite || number >= infiniteThresold ? (
    <span
      data-testid="percentage-infinite"
      style={{
        color,
      }}
      className={infiniteClasses}
    >
      {variation && symbol} &infin; %
    </span>
  ) : (
    <Number
      number={number}
      variationSymbol={variationSymbol}
      isPositive={isPositive}
      variation={variation}
      shapeNumberOptions={{
        ...options,
        format: `0,0.${'0'.repeat(options.decimals as number)}%`,
      }}
      isApproximation={isApproximation}
      {...othersProps}
      neutral={neutral}
      badge={badge}
      className={className}
      color={color}
    />
  )
}

export default Percentage
