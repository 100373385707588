import cx from 'classnames'
import { useEffect, useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import { connect } from 'react-redux'
import { Field, Fields, reduxForm } from 'redux-form'

import { ProductPage } from 'src/components/data/providers/ProductPageProvider'
import AlertsList from 'src/components/ui/atoms/AlertsList'
import Button from 'src/components/ui/atoms/Button'
import InputField from 'src/components/ui/atoms/Forms/Input'
import MetricValue from 'src/components/ui/atoms/MetricValue'
import Modal from 'src/components/ui/atoms/Modal'
import Separator from 'src/components/ui/atoms/Separator'
import TextAreaField from 'src/components/ui/atoms/TextArea/Field'
import Tooltip from 'src/components/ui/atoms/Tooltip'
import EmailsListField from 'src/components/ui/molecules/EmailsList/Field'
import useAccount from 'src/hooks/useAccount'
import useGetTagDetails from 'src/hooks/useGetTagDetails'
import useTracking from 'src/hooks/useTracking'
import { ExportConfig } from 'src/utils/export'
import { alphaNumericAndSpace, notEmpty, required } from 'src/utils/formValidation'
import { TranslateTag } from 'src/utils/search'
import { ComponentCode, FilterBarSettings } from 'src/utils/settings'
import ExportFormat from './components/ExportFormat'
import Frequencies from './components/Frequencies'

import * as styles from './ExportSchedulingForm.scss'

interface Props {
  filterBarSettings: FilterBarSettings
  handleSubmit: any
  selectedFrequency: { label: string; value: string | number }
  exportDashboardConfig: ExportConfig
  page: ProductPage
  onSubmit: () => Promise<any>
  setDefaultFrequency: ({ label, value }: { label: string; value: string | number }) => void
  onClose: (isOpen: boolean) => void
  change: () => void
  [x: string]: any
}

const WARNINGS = {
  columns:
    'Please note choosing more than 7 columns to display in your ecosystem may alter file output.  If you need to display more than 7 columns, please split your ecosystem in two by using the function "Add Ecosystem".',
  frequency: [
    {
      id: 'once',

      msg: 'Alternatively, you can substitute the frequency “Once” with the “download” functionality available next to the run Report button, for a quicker access to your PDF',
    },
    {
      id: 'quarterly',
      msg: 'Dashboards are sent every quarter (Q1 = April, Q2 = July, Q3 = October and Q4 = January).',
    },
  ],
}

const DASHBOARD_DATA_TOOLTIP = {
  timePeriod:
    'Selected time period will impact email frequency options. For optimal experience, please select a default time period (Last seven days, Month to date, Last month etc.).',
  comparisonPeriod:
    ' Selected comparison time period will impact email frequency options. For optimal experience, please select a default comparison time period (Previous days, Previous months, Previous year…).',
}

export const KB_LINK = {
  message: 'Learn more',
  urls: {
    formTitle:
      'https://support.adomik.com/portal/en/kb/articles/report-custom-dashboard-dashboard-layout-based-on-standard-use-cases',
    timePeriod:
      'https://support.adomik.com/portal/en/kb/articles/report-custom-dashboard-best-practices-for-how-to-define-the-time-period-and-frequency-to-share-by-email',
    sales:
      'https://support.adomik.com/portal/en/kb/articles/report-custom-dashboard-dashboard-layout-based-on-standard-use-cases#Sales_Work_out_your_spend_per_agency',
    yield:
      'https://support.adomik.com/portal/en/kb/articles/report-custom-dashboard-dashboard-layout-based-on-standard-use-cases#Yield_management_Receive_alerts_to_reinforce_your_decision-making_process_on_pricing',
    revenue:
      'https://support.adomik.com/portal/en/kb/articles/report-custom-dashboard-dashboard-layout-based-on-standard-use-cases#Management_team_Get_the_full_revenue_data_for_the_previous_days_across_your_entire_advertising_stack',
  },
}

export const ExportSchedulingForm = ({
  filterBarSettings,
  selectedFrequency,
  exportDashboardConfig,
  handleSubmit,
  page,
  onSubmit,
  onClose,
  setDefaultFrequency,
  change,
  submitting,
  ...props
}: Props) => {
  const [entities, setEntities] = useState<TranslateTag | null>(null)
  const [warnings, setWarnings] = useState<ReadonlyArray<string>>([])
  const { informations } = useAccount()
  const ecosystemsSettings = props.settings[page.code]?.[ComponentCode.Ecosystems]?.tables
  const { trackEvent } = useTracking()
  const getTagDetails = useGetTagDetails()

  const columnsLimitReached = ecosystemsSettings?.some((ecosystem: any) => {
    const currentTemplate = ecosystem?.template.id
    return ecosystem[currentTemplate].columns.length > 10
  })
  const formattedEntities = filterBarSettings?.scopes?.reduce(
    (acc: any, scope: any) => ({
      ...acc,
      [scope.dimension.dimension]: [
        ...(acc[scope.dimension.dimension] || []),
        ...(acc[scope.dimension.dimension]?.some((id: any) => id === scope.id) ? [] : [scope.id]),
      ],
    }),
    {},
  )

  const deleteAlert = (item: string) => {
    const warningsList = warnings.filter(warning => warning !== item)
    setWarnings([...warningsList])
  }

  const trackTooltipLink = (label: string) =>
    trackEvent({
      category: 'Tooltips',
      action: 'Report: Scheduled dashboard',
      label,
    })

  useEffect(() => {
    if (filterBarSettings?.scopes?.length) {
      getTagDetails(formattedEntities)?.then(response => {
        setEntities(response)
      })
    }
  }, [filterBarSettings?.scopes])

  useEffect(() => {
    const frequencyWarning: any = WARNINGS.frequency.find(
      warning => warning.id === selectedFrequency?.value,
    )?.msg
    const columnsWarning = warnings.filter(warning => warning === WARNINGS.columns)

    if (frequencyWarning && !warnings.includes(frequencyWarning)) {
      setWarnings([...columnsWarning, frequencyWarning])
    }

    if (!frequencyWarning && warnings.length && !warnings.includes(frequencyWarning)) {
      setWarnings([...columnsWarning])
    }
  }, [selectedFrequency?.value])

  useEffect(() => {
    if (columnsLimitReached && !warnings.includes(WARNINGS.columns)) {
      setWarnings(warning => [...warning, WARNINGS.columns])
    }
  }, [columnsLimitReached])

  return (
    <Modal
      className={styles.SchedulingFormModal}
      onRequestClose={() => onClose(false)}
      open
      isManuallyShaped
      data-testid="schedule-dashboard-modal"
    >
      <Modal.Header className={styles.Header}>
        <div className={styles.HeaderTitle}>
          Schedule Dashboard
          <FiHelpCircle className={styles.InfoIcon} data-for="scheduled_dashboard" data-tip="" />
          <Tooltip
            id="scheduled_dashboard"
            place="right"
            className={styles.Tooltip}
            delayHide={100}
            clickable
          >
            Discover multiple dashboard layout to unleash the full potential of smart advertising
            analytics.
            <br />
            <br />
            <a
              href={KB_LINK.urls.formTitle}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.TooltipLink}
              onClick={() => trackTooltipLink('Title')}
            >
              {KB_LINK.message}
            </a>
          </Tooltip>
        </div>
        <div className={styles.HeaderSubTitle}>
          Share your active Dashboard via automated email to your colleagues and clients.
        </div>
      </Modal.Header>
      <Modal.Body className={styles.Form} noPadding>
        <div className={styles.SectionTitle} id="title">
          Email Settings
        </div>
        <div className={cx(styles.FormSection, styles.FormSectionAttachment)}>
          <label className={styles.Label}>attachment</label>
          <ExportFormat availableFormats={exportDashboardConfig?.export_formats} />
        </div>
        <Separator />
        <div className={styles.FormSection}>
          <label className={styles.Label}>to</label>
          <EmailsListField data-testid="schedule-dashboard-to-input" name="emails" />
        </div>
        <Separator />
        <div className={styles.FormSection}>
          <label className={styles.Label}>file name</label>
          <Field
            name="title"
            component={InputField}
            props={{ type: 'text' }}
            className={styles.Input}
            maxLength={40}
            validate={[required, notEmpty, alphaNumericAndSpace]}
            data-testid="schedule-dashboard-filename-input"
          />
        </div>
        <div className={styles.FormSection}>
          <label className={styles.Label}>message</label>
          <TextAreaField
            data-testid="schedule-dashboard-message-input"
            name="message"
            className={styles.Input}
            maxLength={500}
            count
          />
        </div>
        <Separator />
        <div className={styles.FormSection}>
          <label className={styles.Label}>email frequency</label>
          <Fields
            names={['frequency', 'sub_frequency']}
            component={Frequencies}
            timePeriod={filterBarSettings?.timePeriod}
            config={exportDashboardConfig}
            setDefaultFrequency={setDefaultFrequency}
            trackTooltipLink={trackTooltipLink}
            change={change}
            frequencyDataTestId="schedule-dashboard-frequency-input"
            subFrequencyDataTestId="schedule-dashboard-sub-frequency-input"
          />
        </div>
        <Separator className={styles.Separator} />
        <div
          className={cx({
            [styles.DashboardData]: true,
            [styles.DashboardDataWarnings]: warnings.length,
          })}
        >
          <div className={styles.SectionTitle}>Dashboard Setup</div>
          <div className={styles.SectionSubTitle}>
            Discover multiple Dashboard layouts to cover your{' '}
            <a
              href={KB_LINK.urls.sales}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.Link}
            >
              Sales Enablement
            </a>
            ,{' '}
            <a
              href={KB_LINK.urls.yield}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.Link}
            >
              Yield Management
            </a>
            ,{' '}
            <a
              href={KB_LINK.urls.revenue}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.Link}
            >
              Revenue Management
            </a>
            <br />
            or many other practical use cases.
          </div>
          <div className={styles.FormSection}>
            <div className={styles.Label}>time period</div>
            <div className={styles.DataScopeValue}>
              {filterBarSettings?.timePeriod.selectedRange.name}
            </div>
            <FiHelpCircle className={styles.InfoIcon} data-for="time_period" data-tip="" />
            <Tooltip
              id="time_period"
              place="right"
              className={styles.Tooltip}
              delayHide={100}
              clickable
            >
              {DASHBOARD_DATA_TOOLTIP.timePeriod}
              <br />
              <br />
              <a
                href={KB_LINK.urls.timePeriod}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.TooltipLink}
                onClick={() => trackTooltipLink('Time Period')}
              >
                {KB_LINK.message}
              </a>
            </Tooltip>
          </div>
          <div className={styles.FormSection}>
            <div className={styles.Label}>comparison</div>
            <div className={styles.DataScopeValue}>
              {filterBarSettings?.timePeriod.comparisonDisabled
                ? 'Comparison disabled'
                : filterBarSettings?.timePeriod?.selectedRangeCompare.name}
            </div>
            <FiHelpCircle className={styles.InfoIcon} data-for="comparison_period" data-tip="" />
            <Tooltip
              id="comparison_period"
              place="right"
              className={styles.Tooltip}
              delayHide={500}
              clickable
            >
              {DASHBOARD_DATA_TOOLTIP.comparisonPeriod}
              <br />
              <br />
              <a
                href={KB_LINK.urls.timePeriod}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.TooltipLink}
                onClick={() => trackTooltipLink('Comparison')}
              >
                {KB_LINK.message}
              </a>
            </Tooltip>
          </div>
          <div className={styles.FormSection}>
            <div className={styles.Label}>scope</div>
            <div className={styles.DataScopeValue}>
              {entities &&
                filterBarSettings?.scopes.map((tag: any) => {
                  const entity: any = entities?.[tag.dimension.dimension]?.[tag.id]

                  return (
                    <div key={tag.id}>
                      <span className={styles.EntityName}>{entity?.name}</span>
                      <span>{` - ${entity?.type} - ${
                        tag.id.startsWith('adk-') ? 'Mapped' : 'Unmapped'
                      } ${tag.exclude ? '(Excluded)' : ''}`}</span>
                    </div>
                  )
                })}
            </div>
          </div>
          {ecosystemsSettings?.map((ecosystem: any, _index: number, array: any) => {
            const currentTemplate = ecosystem?.template.id
            const customMode = ecosystem[currentTemplate]?.custom

            return (
              <div className={styles.FormSection} key={ecosystem.id}>
                <div className={styles.Label}>{`ecosystem ${ecosystem.id + 1}/${
                  array.length
                }`}</div>
                <div className={cx(styles.DataScopeValue, styles.DataScopeValueSlicers)}>
                  <div>
                    <span className={cx(styles.EntityName, styles.EntityNameCapitalize)}>
                      {ecosystem.primaryDimension.id.replace(/_/g, ' ')}
                    </span>{' '}
                    - {`${customMode ? 'Custom ' : ''} ${ecosystem.template.label} `} template
                  </div>
                  {ecosystem[currentTemplate].filters.length ? (
                    ecosystem[currentTemplate].filters
                      .filter((filter: any) => !filter.check)
                      .filter((filter: any) => filter.active)
                      .map((filter: any) => {
                        const defaultMin = filter.config.defaultValue.min === filter.value.min
                        const defaultMax = filter.config.defaultValue.max === filter.value.max

                        return (
                          <div key={filter.id}>
                            <span>{filter.label}</span>
                            {filter.value.min !== undefined && (
                              <>
                                {defaultMin && !filter.value.max ? ' > ' : ': '}
                                <MetricValue
                                  metric={
                                    {
                                      code: filter.id,
                                      raw: filter.value.min,
                                      type: filter.config.metricConfig.type,
                                    } as any
                                  }
                                  currency={informations.currency}
                                  shapeNumberOptions={
                                    filter.config.metricConfig.type === 'rawPercentage'
                                      ? { decimals: 2 }
                                      : {}
                                  }
                                  variation={filter.config.metricConfig.variation}
                                  variationSymbol={
                                    filter.config.metricConfig.rawShape
                                      ? filter.config.metricConfig.rawShape.variation_symbol
                                      : filter.config.absolute
                                        ? 'none'
                                        : 'plus_minus'
                                  }
                                />
                              </>
                            )}
                            {filter.value.max !== undefined && (
                              <>
                                {defaultMax && !filter.value.min ? ` < ` : ` to `}
                                <MetricValue
                                  metric={
                                    {
                                      code: filter.id,
                                      raw: filter.value.max,
                                      type: filter.config.metricConfig.type,
                                    } as any
                                  }
                                  currency={informations.currency}
                                  shapeNumberOptions={
                                    filter.config.metricConfig.type === 'rawPercentage'
                                      ? { decimals: 2 }
                                      : {}
                                  }
                                  variation={filter.config.metricConfig.variation}
                                  variationSymbol={
                                    filter.config.metricConfig.rawShape
                                      ? filter.config.metricConfig.rawShape.variation_symbol
                                      : filter.config.absolute
                                        ? 'none'
                                        : 'plus_minus'
                                  }
                                />
                              </>
                            )}
                          </div>
                        )
                      })
                  ) : (
                    <div className={styles.NoSlicers}>No active slicer</div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.Warnings}>
          <AlertsList alerts={warnings} onClose={deleteAlert} />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.FormFooter}>
        <Button buttonType="silence" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button
          disabled={submitting}
          buttonType="success"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Validate
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = (state: any) => ({
  settings: state.componentsSettings.settings,
})
const mapDispatchToProps = {}

export default reduxForm({
  form: 'ExportSchedulingForm',
  touchOnChange: true,
  enableReinitialize: true,
  onSubmitFail: (errors: any) => {
    const title = document.getElementById('title')
    if (errors) {
      title?.scrollIntoView({ behavior: 'smooth' })
    }
  },
})(connect(mapStateToProps, mapDispatchToProps)(ExportSchedulingForm))
