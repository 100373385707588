import cx from 'classnames'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import Button from 'src/components/ui/atoms/Button'
import Send from 'src/components/ui/atoms/Icons/Send'
import useFetch from 'src/hooks/useFetch'
import useProductPage from 'src/hooks/useProductPage'
import useTracking from 'src/hooks/useTracking'
import { ComponentsSettingsStoreProps } from 'src/redux/reducers/componentsSettingsReducer'
import { RootStoreProps } from 'src/redux/reducers/root'
import { getApiUrl } from 'src/utils'
import { ExportConfig } from 'src/utils/export'
import { ComponentCode } from 'src/utils/settings'
import { trackSchedulingFormValues } from 'src/utils/tracking'
import ExportSchedulingForm from '../../../ui/organisms/ExportScheduling/ExportSchedulingForm'

import * as styles from './ExportSchedulingContainer.scss'

interface FormValues {
  format: string
  emails: ReadonlyArray<string>
  title: string
  message: string
  frequency: { label: string; value: string }
  sub_frequency: { label: string; value: string | number }
  delivery_duration: { label: string; value: number }
}

interface DefaultValue {
  frequency: {
    label: string
    value: string | number
  }
  subFrequency?: any
}

interface ExportSchedulingContainerProps {
  selectedFrequency?: string
  exportDashboardConfig: ExportConfig
  className?: string
  [x: string]: any
}

export const ExportSchedulingContainer = ({
  selectedFrequency,
  exportDashboardConfig,
  className,
  ...rest
}: ExportSchedulingContainerProps) => {
  const props = { ...rest } as RootStoreProps & ComponentsSettingsStoreProps
  const [open, setOpen] = useState<boolean>(false)
  const [defaultFrequency, setDefaultFrequency] = useState<DefaultValue>()
  const page = useProductPage()
  const filterBarSettings = props.settings[page.code]?.[ComponentCode.FilterBar] as any
  const { trackEvent } = useTracking()

  const [, executeExportSchedulingRequest] = useFetch(
    {
      url: `${getApiUrl()}/api/admin/export_configs`,
      method: 'POST',
    },
    { manual: true },
  )

  const exportSchedulingRequest = async (data: any) => {
    try {
      await executeExportSchedulingRequest({
        data: {
          export_config: {
            format: data.format,
            email_informations: {
              recipients: data.emails,
              title: data.title,
              message: data.message,
            },
            scheduling_informations: {
              frequency: data.frequency.value,
              sub_frequency: data.sub_frequency?.value,
              delivery_duration: data.delivery_duration?.value,
            },
            target_period_informations: {
              compare_to: filterBarSettings?.timePeriod?.selectedRangeCompare,
              period: filterBarSettings?.timePeriod?.selectedRange,
              frequency:
                filterBarSettings?.timePeriod.frequency || filterBarSettings?.timePeriod.view,
              custom_period: {
                start_date: filterBarSettings?.timePeriod.startDate,
                end_date: filterBarSettings?.timePeriod.endDate,
                previous_start_date: filterBarSettings?.timePeriod.startDateCompare,
                previous_end_date: filterBarSettings?.timePeriod.endDateCompare,
              },
            },
            sub_product: page.code,
            scopes: filterBarSettings.scopes,
            breakdown: filterBarSettings?.breakdown,
            components_settings: props.settings,
          },
        },
      })
      toast.success('Scheduled dashboard successfully created')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error })
      toast.error('An error occurred. Please retry.')
    } finally {
      setOpen(false)
    }
  }

  const onSubmitExportSchedulingForm = ({
    format,
    emails,
    title,
    message,
    frequency,
    sub_frequency,
    delivery_duration,
  }: FormValues) => {
    const formValues = {
      format,
      emails,
      title,
      message,
      frequency,
      sub_frequency,
      delivery_duration,
    }
    const trackedValues = (({ frequency, sub_frequency, delivery_duration }) => ({
      emails,
      frequency,
      sub_frequency,
      delivery_duration,
    }))(formValues)

    trackEvent({ action: 'Click: Share validate' })
    trackSchedulingFormValues(trackedValues, trackEvent)
    return exportSchedulingRequest(formValues)
  }

  const isLoadingDone = props.loadingComponents.every(component => !component.loading)

  useEffect(() => {
    open && trackEvent({ action: 'Click: Share icon' })
  }, [open])

  return (
    <>
      <Button
        buttonType="silence"
        onClick={() => isLoadingDone && setOpen(!open)}
        className={cx(
          {
            [styles.ShareIconWrapper]: true,
            [styles.ShareIconWrapperDisabled]: !isLoadingDone,
          },
          className,
        )}
        aria-label="Schedule Dashboard button"
      >
        <Send />
      </Button>
      {open && (
        <ExportSchedulingForm
          onSubmit={onSubmitExportSchedulingForm}
          initialValues={{
            emails: [],
            frequency: defaultFrequency?.frequency,
            format: 'pdf',
            sub_frequency: defaultFrequency?.subFrequency,
          }}
          filterBarSettings={filterBarSettings}
          onClose={setOpen}
          setDefaultFrequency={setDefaultFrequency}
          selectedFrequency={selectedFrequency}
          exportDashboardConfig={exportDashboardConfig}
          page={page}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  selectedFrequency: formValueSelector('ExportSchedulingForm')(state, 'frequency'),
  settings: state.componentsSettings.settings,
  loadingComponents: state.root.loadingComponents,
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExportSchedulingContainer)
